// extracted by mini-css-extract-plugin
export var root = "PlasmicTopSection-module--root--8bAT5";
export var box__ktn0N = "PlasmicTopSection-module--box__ktn0N--10e2k";
export var box__iQsF = "PlasmicTopSection-module--box__iQsF--34mQ2";
export var box__umjWn = "PlasmicTopSection-module--box__umjWn--21Vfr";
export var box__sF88N = "PlasmicTopSection-module--box__sF88N--1xA6e";
export var box__pPjtW = "PlasmicTopSection-module--box__pPjtW--2in6k";
export var box___9AnlQ = "PlasmicTopSection-module--box___9AnlQ--2d8CU";
export var box__gVw6X = "PlasmicTopSection-module--box__gVw6X--2DG6x";
export var linkButton__c28Yy = "PlasmicTopSection-module--linkButton__c28Yy--3ZdeU";
export var linkButton__jS5Ml = "PlasmicTopSection-module--linkButton__jS5Ml--2vq4a";
export var box___9U76J = "PlasmicTopSection-module--box___9U76J--1IFwg";
export var img = "PlasmicTopSection-module--img--22FGd";