// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatureCard-module--root--pXa-D";
export var root__long = "PlasmicFeatureCard-module--root__long--Z3-XW";
export var box___6MbK = "PlasmicFeatureCard-module--box___6MbK--11-RR";
export var box__ceXkk = "PlasmicFeatureCard-module--box__ceXkk--zdflP";
export var svg__q2RLx = "PlasmicFeatureCard-module--svg__q2RLx--31cWG";
export var box__w2GTg = "PlasmicFeatureCard-module--box__w2GTg--1kDHX";
export var box__long__w2GTgLk2Wm = "PlasmicFeatureCard-module--box__long__w2GTgLk2Wm--3xLnB";
export var slotTitle = "PlasmicFeatureCard-module--slotTitle--ikjab";
export var slotDescription = "PlasmicFeatureCard-module--slotDescription--qbOZz";
export var slotDescription__long = "PlasmicFeatureCard-module--slotDescription__long--2x1ry";
export var box__jLw9 = "PlasmicFeatureCard-module--box__jLw9--1asK_";
export var box__long__jLw9Lk2Wm = "PlasmicFeatureCard-module--box__long__jLw9Lk2Wm--2spjF";
export var svg__k2Ety = "PlasmicFeatureCard-module--svg__k2Ety--1_Kmz";